import { InMemoryCache } from "shared/providers/apollo/apollo.package";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getDistricts: {
          keyArgs: ["search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getDistrictAdmins: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getSchools: {
          keyArgs: ["districtId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getUsers: {
          keyArgs: ["districtId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getTerms: {
          keyArgs: ["districtId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getAnecdotalTypes: {
          keyArgs: ["districtId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
      },
    },
    FileModel: {
      keyFields: false,
    },
    IntegrationListModel: {
      keyFields: false,
    },
    IntegrationSetupModel: {
      keyFields: false,
    },
    CleverSchoolModel: {
      keyFields: ["cleverId"],
    },
  },
});

export default cache;
