import { useCallback } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import Label from "../label/label.component";
import Error from "../error/error.component";

import { UploaderProps } from "./uploader.interfaces";
import UploaderWrapper, { UploaderContainer } from "./uploader.styles";

import Spinner from "../../spinner/spinner.component";
import Button from "../../button/button.component";
import Separator from "../../separator/separator.styles";

import { TbFiles } from "react-icons/tb";

export const Uploader = ({
  $width,
  id,
  name,
  label,
  labelColor,
  labelTooltip,
  labelSeparator,
  hasError,
  errorMessage,
  removeErrorMessage,
  required,
  onBlur,
  customPlaceholder,
  placeholderIcon,
  placeholderText,
  placeholderDescription,
  showFooter,
  $spacing,
  disabled,
  loading,
  dropZoneOptions,
  displayFlex,
  flex,
  flexDirection,
  flexWrap,
  alignItems,
  justifyContent,
  ...props
}: UploaderProps) => {
  const onDrop = useCallback(
    async (accepted: File[], rejected: FileRejection[]) => {
      if (!props.multiple && props.onDelete && props.value && props.deleteOnChange) {
        props.onDelete(props.value, id);
      }

      if (rejected && rejected.length) {
        toast.error("An error occurred while uploading files.");
      } else if (props.onChange) {
        props.onChange(accepted, id);
      }
    },
    [id, props]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "text/csv": [".csv"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
        ".docx",
      ],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
        ".pptx",
      ],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    onDrop,
    ...dropZoneOptions,
    multiple: props.multiple,
    disabled,
  });

  return (
    <UploaderWrapper
      $width={$width}
      $spacing={$spacing}
      displayFlex={displayFlex}
      flex={flex}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <UploaderContainer $disabled={disabled}>
        {label ? (
          <>
            <Label
              required={required}
              htmlFor={id}
              $color={labelColor}
              tooltip={labelTooltip}
            >
              {label}
            </Label>

            {labelSeparator ? (
              <Separator
                $spacing={{
                  my: "16px",
                }}
              />
            ) : null}
          </>
        ) : null}

        <div className="uploader__inner-wrapper">
          {loading ? (
            <Spinner
              $spacing={{
                py: "16px",
              }}
            />
          ) : (
            <div {...getRootProps()} className="uploader__inner">
              <input
                {...getInputProps({
                  id,
                  name,
                  onBlur,
                })}
              />

              {customPlaceholder ? (
                customPlaceholder
              ) : !props.multiple && props.valueImagePreview && props.value ? (
                <img
                  className="uploader__preview"
                  src={props.value.url}
                  alt={props.value.name}
                />
              ) : (
                <>
                  <div className="uploader__icon">
                    {placeholderIcon ? placeholderIcon : <TbFiles />}
                  </div>

                  <div className="uploader__text">
                    {placeholderText ? (
                      placeholderText
                    ) : !props.multiple && props.value ? (
                      <>
                        <div>File: {props.value.name}</div>
                        <div>Size: {props.value.size}KB</div>
                      </>
                    ) : (
                      <>
                        <strong>Select file to upload</strong>
                        <div>or drag and drop it here</div>
                      </>
                    )}
                  </div>

                  <div className="uploader__description">
                    {placeholderDescription
                      ? placeholderDescription
                      : !props.multiple && props.value
                      ? `${props.value.name} has size: ${props.value.size}`
                      : "Formats: JPEG, PNG, PDF, DOC, CSV, MP4"}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {showFooter && !disabled && !props.multiple && props.value ? (
          <>
            <Separator
              $spacing={{
                my: "16px",
              }}
            />

            <div className="uploader__footer">
              {!props.multiple && props.onDelete && props.value ? (
                <Button
                  $size="small"
                  variant="secondary"
                  onClick={() =>
                    props.onDelete && props.value
                      ? props.onDelete(props.value, id)
                      : null
                  }
                  isLoading={props.deleteLoading}
                  disabled={props.deleteLoading}
                  $spacing={{
                    mr: "7px",
                  }}
                >
                  Remove
                </Button>
              ) : null}

              <Button $size="small" onClick={() => open()}>
                {!props.multiple && props.value ? "Replace" : "Add"}
              </Button>
            </div>
          </>
        ) : null}
      </UploaderContainer>

      {hasError && !removeErrorMessage && (
        <Error>{errorMessage || "Field is required"}</Error>
      )}
    </UploaderWrapper>
  );
};

export default Uploader;
