import React, { lazy, useCallback } from "react";

import ThemeProvider from "shared/providers/styled-components/theme.provider";
import ToastProvider from "shared/providers/toast/toast.provider";
import { Route, Routes, useNavigate } from "shared/providers/router/router.package";

import NotFound from "shared/components/not-found/not-found.component";

import Layout from "../../containers/layout/layout.container";
import ProtectedRoute from "../../hoc/protected-route/protected-route.hoc";
import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";

import NewDistrict from "../../pages/districts/new-district/new-district.component";

import defaultTheme from "shared/styles/Theme";

import GlobalStyles from "shared/styles";

const Login = withSuspense(
  lazy(() => import("../../pages/login/login.page")),
  false,
  "100vh"
);
const ForgottenPassword = withSuspense(
  lazy(() => import("../../pages/forgotten-password/forgotten.page")),
  false,
  "100vh"
);
const ResetPassword = withSuspense(
  lazy(() => import("../../pages/reset-password/reset.page")),
  false,
  "100vh"
);
const Districts = withSuspense(
  lazy(() => import("../../pages/districts/districts.page")),
  false,
  "full-layout"
);
const DistrictAdminUsersRoutes = withSuspense(
  lazy(() => import("../../pages/users/users.routes")),
  false,
  "full-layout"
);
const SingleDistrictRoutes = withSuspense(
  lazy(() => import("../../pages/single-district/single-district.routes")),
  false,
  "full-layout"
);

const App: React.FC = () => {
  const navigate = useNavigate();

  const closeDistrictModal = useCallback(() => navigate("/districts"), [navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <ToastProvider />

      <Routes>
        {["/", "login"].map((path) => (
          <Route key={path} path={path} element={<Login />} />
        ))}

        <Route path="forgotten-password" element={<ForgottenPassword />} />

        <Route path="reset-password/:code" element={<ResetPassword />} />

        <Route
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          {/* We can move this to separate file with nested Routes */}
          <Route path="districts" element={<Districts />}>
            <Route
              path="new"
              element={<NewDistrict closeHandler={closeDistrictModal} />}
            />
          </Route>

          {/* Single district route logic */}
          <Route path="districts/:id/*" element={<SingleDistrictRoutes />} />

          <Route path="users/*" element={<DistrictAdminUsersRoutes />} />
        </Route>

        <Route path="*" element={<NotFound url="/districts" />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
