import { forwardRef, Fragment, useState } from "react";
import InputWrapper from "./input.styles";
import { IInputProps } from "./input.interfaces";

import Label from "../label/label.component";
import Error from "../error/error.component";

import { TbEyeOff, TbEye } from "react-icons/tb";

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      name,
      value,
      disabled,
      required,
      placeholder,
      label,
      labelColor,
      labelTooltip,
      hasError,
      errorMessage,
      removeErrorMessage,
      inputProps,
      prependElement,
      appendElement,
      hidePasswordVisibility,
      onChange,
      onBlur,
      ...rest
    },
    ref
  ) => {
    const [passVisible, setPassVisible] = useState(false);

    return (
      <Fragment>
        {label ? (
          <Label
            required={required}
            htmlFor={id}
            $color={labelColor}
            tooltip={labelTooltip}
          >
            {label}
          </Label>
        ) : null}

        <InputWrapper
          hasError={hasError}
          hasElementBefore={!!prependElement}
          hasElementAfter={
            !!appendElement ||
            (!hidePasswordVisibility && inputProps?.type === "password")
          }
          {...rest}
        >
          <div className="input__container">
            {prependElement && (
              <div className="additional-input-element additional-input-element--before">
                {prependElement}
              </div>
            )}

            <input
              id={id}
              name={name}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              {...inputProps}
              aria-invalid={hasError}
              type={passVisible ? "text" : inputProps?.type}
            />

            {(appendElement ||
              (!hidePasswordVisibility && inputProps?.type === "password")) && (
              <div className="additional-input-element additional-input-element--after">
                {appendElement ? (
                  appendElement
                ) : passVisible ? (
                  <TbEyeOff
                    className={disabled ? undefined : "pointer"}
                    onClick={disabled ? undefined : () => setPassVisible(false)}
                  />
                ) : (
                  <TbEye
                    className={disabled ? undefined : "pointer"}
                    onClick={disabled ? undefined : () => setPassVisible(true)}
                  />
                )}
              </div>
            )}
          </div>

          {hasError && !removeErrorMessage && (
            <Error>{errorMessage || "Field is required"}</Error>
          )}
        </InputWrapper>
      </Fragment>
    );
  }
);

export default Input;
