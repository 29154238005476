import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AnecdotalTypeInput = {
  districtId: Scalars['String'];
  names: Array<Scalars['String']>;
};

export type AnecdotalTypeModel = {
  __typename?: 'AnecdotalTypeModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  districtId: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  school?: Maybe<SchoolModel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnecdotalTypePaginationModel = {
  __typename?: 'AnecdotalTypePaginationModel';
  data?: Maybe<Array<AnecdotalTypeModel>>;
  pagination: CursorPagination;
};

export type AttendanceStatusInput = {
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shortName?: InputMaybe<Scalars['String']>;
};

export type AttendanceStatusObject = {
  __typename?: 'AttendanceStatusObject';
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClassGoogleModel = {
  __typename?: 'ClassGoogleModel';
  assignments: Scalars['Boolean'];
  connected: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  users: Scalars['Boolean'];
};

export type ClassModel = {
  __typename?: 'ClassModel';
  cleverId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  googleData: ClassGoogleModel;
  gradebookSetting: GradebookSettingModel;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  markingPeriods: Array<MarkingPeriodModel>;
  name: Scalars['String'];
  room?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolModel>;
  schoolId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<ClassUserModel>;
};

export type ClassUserModel = {
  __typename?: 'ClassUserModel';
  deactivatedInSync: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  user: UserSchoolModel;
  userFromCleverSync: Scalars['Boolean'];
};

export type CleverCourseModel = {
  __typename?: 'CleverCourseModel';
  cleverId: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
};

export type CleverCoursesInput = {
  data: Array<CleverSingleCourseInput>;
  districtId: Scalars['String'];
};

export type CleverSchoolModel = {
  __typename?: 'CleverSchoolModel';
  cleverId: Scalars['String'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  practiceSchool?: Maybe<SchoolModel>;
  practiceStatus: ProviderStatusEnum;
};

export type CleverSchoolsInput = {
  data: Array<CleverSingleSchoolInput>;
  districtId: Scalars['String'];
};

export type CleverSingleCourseInput = {
  cleverId: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
};

export type CleverSingleSchoolInput = {
  cleverId: Scalars['String'];
  lastSyncedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
};

export type CleverSingleTermInput = {
  cleverId: Scalars['String'];
  fromDate: Scalars['DateTime'];
  lastSyncedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  toDate: Scalars['DateTime'];
};

export type CleverTermModel = {
  __typename?: 'CleverTermModel';
  cleverId: Scalars['String'];
  fromDate: Scalars['DateTime'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  practiceStatus: ProviderStatusEnum;
  practiceTerm?: Maybe<TermModel>;
  toDate: Scalars['DateTime'];
};

export type CleverTermsInput = {
  data: Array<CleverSingleTermInput>;
  districtId: Scalars['String'];
};

export type CommentTemplateModel = {
  __typename?: 'CommentTemplateModel';
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentTemplatePaginationModel = {
  __typename?: 'CommentTemplatePaginationModel';
  data?: Maybe<Array<CommentTemplateModel>>;
  pagination: CursorPagination;
};

export type CourseInput = {
  districtId: Scalars['String'];
  name: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
};

export type CourseModel = {
  __typename?: 'CourseModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CoursePaginationModel = {
  __typename?: 'CoursePaginationModel';
  data?: Maybe<Array<CourseModel>>;
  pagination: CursorPagination;
};

export type CourseUpdateInput = {
  name: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
};

export type CreateCommentTemplateInput = {
  comment: Scalars['String'];
};

export type CreateDistrictAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type CursorPagination = {
  __typename?: 'CursorPagination';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type DistrictAdminAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe: Scalars['Boolean'];
};

export type DistrictAdminModel = {
  __typename?: 'DistrictAdminModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DistrictAdminPaginationModel = {
  __typename?: 'DistrictAdminPaginationModel';
  data?: Maybe<Array<DistrictAdminModel>>;
  pagination: CursorPagination;
};

export type DistrictInput = {
  background?: InputMaybe<FileObjectInput>;
  favicon?: InputMaybe<FileObjectInput>;
  logo?: InputMaybe<FileObjectInput>;
  name: Scalars['String'];
  timezone: Scalars['String'];
  url: Scalars['String'];
};

export type DistrictModel = {
  __typename?: 'DistrictModel';
  background?: Maybe<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<FileModel>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<FileModel>;
  name: Scalars['String'];
  schools?: Maybe<Array<SchoolModel>>;
  schoolsCount?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<Array<UserModel>>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type DistrictPaginationModel = {
  __typename?: 'DistrictPaginationModel';
  data?: Maybe<Array<DistrictModel>>;
  pagination: CursorPagination;
};

export type FileModel = {
  __typename?: 'FileModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type FileObjectInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type GradeScaleGrade = {
  __typename?: 'GradeScaleGrade';
  grade: Scalars['String'];
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type GradeScaleModel = {
  __typename?: 'GradeScaleModel';
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  scales: Array<GradeScaleGrade>;
};

export type GradebookSettingModel = {
  __typename?: 'GradebookSettingModel';
  gradeScale?: Maybe<GradeScaleModel>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  school: SchoolModel;
  type: GradebookSettingTypeEnum;
  values: Array<GradebookSettingValuesModel>;
};

export enum GradebookSettingTypeEnum {
  NoOverallGrade = 'NoOverallGrade',
  TotalPoints = 'TotalPoints',
  WeightedByCategory = 'WeightedByCategory'
}

export type GradebookSettingValuesModel = {
  __typename?: 'GradebookSettingValuesModel';
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type IntegrationBlocksDto = {
  __typename?: 'IntegrationBlocksDTO';
  description?: Maybe<Scalars['String']>;
  isDisable: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  required: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type IntegrationInput = {
  keys: Array<IntegrationSettingsInput>;
  type: ProviderEnum;
};

export type IntegrationListModel = {
  __typename?: 'IntegrationListModel';
  hasIntegration: Scalars['Boolean'];
  status: Scalars['Boolean'];
  type: ProviderEnum;
};

export type IntegrationSettingsInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type IntegrationSetupModel = {
  __typename?: 'IntegrationSetupModel';
  hasIntegration: Scalars['Boolean'];
  keys: Array<IntegrationBlocksDto>;
  status: Scalars['Boolean'];
  type: ProviderEnum;
};

export type LoginModel = {
  __typename?: 'LoginModel';
  token: Scalars['String'];
};

export type MarkingPeriodModel = {
  __typename?: 'MarkingPeriodModel';
  createdAt?: Maybe<Scalars['Boolean']>;
  fromDate: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  term: TermModel;
  toDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type MultipleIdsInput = {
  ids: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAnecdotalType: Scalars['Boolean'];
  createAttendanceStatus: AttendanceStatusObject;
  createCommentTemplate: CommentTemplateModel;
  createCourse: CourseModel;
  createDistrict: DistrictModel;
  createDistrictAdmin: DistrictAdminModel;
  createSchool: SchoolModel;
  createTerm: TermModel;
  createUser: UserModel;
  deleteCommentTemplates: Scalars['Boolean'];
  districtAdminAuth: LoginModel;
  forgotPassword: Scalars['Boolean'];
  hardRemoveAnecdotalType: AnecdotalTypeModel;
  reactivateAnecdotalTypes: Scalars['Boolean'];
  reactivateAttendanceStatus: Scalars['Boolean'];
  reactivateDistricts: Scalars['Boolean'];
  reactivateSchools: Scalars['Boolean'];
  reactivateTerms: Scalars['Boolean'];
  reactivateUsers: Scalars['Boolean'];
  removeAnecdotalTypes: Scalars['Boolean'];
  removeAttendanceStatus: Scalars['Boolean'];
  removeCourses: Scalars['Boolean'];
  removeDistrictAdmins: Scalars['Boolean'];
  removeDistricts: Scalars['Boolean'];
  removeIntegration: Scalars['Boolean'];
  removeSchools: Scalars['Boolean'];
  removeTerms: Scalars['Boolean'];
  removeUsers: Scalars['Boolean'];
  reorderAttendanceStatuses: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  storeCleverCourses: Scalars['Boolean'];
  storeCleverSchools: Scalars['Boolean'];
  storeCleverTerms: Scalars['Boolean'];
  storeIntegration: IntegrationSetupModel;
  toggleIntegration: IntegrationSetupModel;
  updateAttendanceStatus: AttendanceStatusObject;
  updateCommentTemplate: CommentTemplateModel;
  updateCourse: CourseModel;
  updateDistrict: DistrictModel;
  updateDistrictAdmin: DistrictAdminModel;
  updateSchool: SchoolModel;
  updateTerm: TermModel;
  updateUser: UserModel;
  uploadFiles: Array<UploadFileInput>;
};


export type MutationCreateAnecdotalTypeArgs = {
  input: AnecdotalTypeInput;
};


export type MutationCreateAttendanceStatusArgs = {
  districtId: Scalars['String'];
  input: AttendanceStatusInput;
};


export type MutationCreateCommentTemplateArgs = {
  districtId: Scalars['String'];
  input: CreateCommentTemplateInput;
};


export type MutationCreateCourseArgs = {
  input: CourseInput;
};


export type MutationCreateDistrictArgs = {
  input: DistrictInput;
};


export type MutationCreateDistrictAdminArgs = {
  input: CreateDistrictAdminInput;
};


export type MutationCreateSchoolArgs = {
  districtId: Scalars['String'];
  input: SchoolInput;
};


export type MutationCreateTermArgs = {
  districtId: Scalars['String'];
  input: TermInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteCommentTemplatesArgs = {
  input: MultipleIdsInput;
};


export type MutationDistrictAdminAuthArgs = {
  input: DistrictAdminAuthInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationHardRemoveAnecdotalTypeArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateAnecdotalTypesArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateAttendanceStatusArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateDistrictsArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateSchoolsArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateTermsArgs = {
  input: MultipleIdsInput;
};


export type MutationReactivateUsersArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveAnecdotalTypesArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveAttendanceStatusArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveCoursesArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveDistrictAdminsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveDistrictsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveIntegrationArgs = {
  districtId: Scalars['String'];
  input: ToggleIntegrationInput;
};


export type MutationRemoveSchoolsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveTermsArgs = {
  input: MultipleIdsInput;
};


export type MutationRemoveUsersArgs = {
  input: MultipleIdsInput;
};


export type MutationReorderAttendanceStatusesArgs = {
  id: Scalars['String'];
  secondId: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationStoreCleverCoursesArgs = {
  input: CleverCoursesInput;
};


export type MutationStoreCleverSchoolsArgs = {
  input: CleverSchoolsInput;
};


export type MutationStoreCleverTermsArgs = {
  input: CleverTermsInput;
};


export type MutationStoreIntegrationArgs = {
  districtId: Scalars['String'];
  input: IntegrationInput;
};


export type MutationToggleIntegrationArgs = {
  districtId: Scalars['String'];
  input: ToggleIntegrationInput;
};


export type MutationUpdateAttendanceStatusArgs = {
  id: Scalars['String'];
  input: AttendanceStatusInput;
};


export type MutationUpdateCommentTemplateArgs = {
  id: Scalars['String'];
  input: CreateCommentTemplateInput;
};


export type MutationUpdateCourseArgs = {
  id: Scalars['String'];
  input: CourseUpdateInput;
};


export type MutationUpdateDistrictArgs = {
  id: Scalars['String'];
  input: DistrictInput;
};


export type MutationUpdateDistrictAdminArgs = {
  id: Scalars['String'];
  input: UpdateDistrictAdminInput;
};


export type MutationUpdateSchoolArgs = {
  id: Scalars['String'];
  input: SchoolInput;
};


export type MutationUpdateTermArgs = {
  id: Scalars['String'];
  input: TermInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: UpdateUserInput;
};


export type MutationUploadFilesArgs = {
  files: Array<Scalars['Upload']>;
};

export type ParentModel = {
  __typename?: 'ParentModel';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum ProviderEnum {
  Clever = 'Clever',
  Google = 'Google',
  GoogleLogin = 'GoogleLogin'
}

export type ProviderModel = {
  __typename?: 'ProviderModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProviderStatusEnum {
  Ignore = 'ignore',
  Map = 'map',
  New = 'new',
  NotImported = 'not_imported'
}

export type Query = {
  __typename?: 'Query';
  getAnecdotalTypes: AnecdotalTypePaginationModel;
  getAttendanceStatus: AttendanceStatusObject;
  getAttendanceStatuses: Array<AttendanceStatusObject>;
  getCleverCourses: Array<CleverCourseModel>;
  getCleverSchools: Array<CleverSchoolModel>;
  getCleverTerms: Array<CleverTermModel>;
  getCommentTemplate: CommentTemplateModel;
  getCommentTemplates: CommentTemplatePaginationModel;
  getCourse: CourseModel;
  getCourses: CoursePaginationModel;
  getDistrict: DistrictModel;
  getDistrictAdmin: DistrictAdminModel;
  getDistrictAdmins: DistrictAdminPaginationModel;
  getDistricts: DistrictPaginationModel;
  getIntegration: IntegrationSetupModel;
  getIntegrations: Array<IntegrationListModel>;
  getRoles: Array<RoleModel>;
  getSchool: SchoolModel;
  getSchools: SchoolPaginationModel;
  getTerm: TermModel;
  getTerms: TermPaginationModel;
  getUser: UserModel;
  getUserGradeList: Array<Scalars['String']>;
  getUsers: UserPaginationModel;
  me: DistrictAdminModel;
};


export type QueryGetAnecdotalTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  districtId: Scalars['String'];
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAttendanceStatusArgs = {
  districtId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetAttendanceStatusesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  districtId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetCleverCoursesArgs = {
  districtId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetCleverSchoolsArgs = {
  districtId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetCleverTermsArgs = {
  districtId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  status: ProviderStatusEnum;
};


export type QueryGetCommentTemplateArgs = {
  id: Scalars['String'];
};


export type QueryGetCommentTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetCourseArgs = {
  id: Scalars['String'];
};


export type QueryGetCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetDistrictArgs = {
  id: Scalars['String'];
};


export type QueryGetDistrictAdminArgs = {
  id: Scalars['String'];
};


export type QueryGetDistrictAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetDistrictsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetIntegrationArgs = {
  districtId: Scalars['String'];
  type: ProviderEnum;
};


export type QueryGetIntegrationsArgs = {
  districtId: Scalars['String'];
};


export type QueryGetSchoolArgs = {
  districtId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetSchoolsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  districtId?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetTermArgs = {
  districtId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  districtId: Scalars['String'];
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  districtId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetUserGradeListArgs = {
  districtId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  districtId?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type RoleModel = {
  __typename?: 'RoleModel';
  id: Scalars['String'];
  name: Scalars['String'];
  type: RoleTypeEnum;
};

export enum RoleTypeEnum {
  Parent = 'parent',
  SchoolAdmin = 'schoolAdmin',
  Student = 'student',
  Teacher = 'teacher'
}

export type SchoolInput = {
  background?: InputMaybe<FileObjectInput>;
  defaultGradebookId?: InputMaybe<Scalars['String']>;
  defaultMarkingPeriodId?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileObjectInput>;
  name: Scalars['String'];
};

export type SchoolModel = {
  __typename?: 'SchoolModel';
  background?: Maybe<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultGradebook?: Maybe<GradebookSettingModel>;
  defaultMarkingPeriod?: Maybe<MarkingPeriodModel>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<FileModel>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<UserSchoolModel>>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type SchoolPaginationModel = {
  __typename?: 'SchoolPaginationModel';
  data?: Maybe<Array<SchoolModel>>;
  pagination: CursorPagination;
};

export type SubmissionModel = {
  __typename?: 'SubmissionModel';
  attachments: Array<FileModel>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: ClassUserModel;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TermInput = {
  fromDate: Scalars['DateTime'];
  name: Scalars['String'];
  toDate: Scalars['DateTime'];
};

export type TermModel = {
  __typename?: 'TermModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  fromDate: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  markingPeriods?: Maybe<Array<MarkingPeriodModel>>;
  name: Scalars['String'];
  school?: Maybe<SchoolModel>;
  toDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TermPaginationModel = {
  __typename?: 'TermPaginationModel';
  data?: Maybe<Array<TermModel>>;
  pagination: CursorPagination;
};

export type ToggleIntegrationInput = {
  type: ProviderEnum;
};

export type UpdateDistrictAdminInput = {
  firstName: Scalars['String'];
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
};

export type UpdateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  schoolAccess: Array<UserAccessInput>;
};

export type UploadFileInput = {
  __typename?: 'UploadFileInput';
  name: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type UserAccessInput = {
  grade?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  schoolId: Scalars['String'];
  status: UserStatuses;
};

export type UserAccessModel = {
  __typename?: 'UserAccessModel';
  role: RoleModel;
  school: SchoolModel;
  status: UserStatuses;
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<FileObjectInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  schoolAccess: Array<UserAccessInput>;
};

export type UserModel = {
  __typename?: 'UserModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  schoolAccess: Array<UserAccessModel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserPaginationModel = {
  __typename?: 'UserPaginationModel';
  data?: Maybe<Array<UserModel>>;
  pagination: CursorPagination;
};

export type UserSchoolModel = {
  __typename?: 'UserSchoolModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<FileModel>;
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  parents?: Maybe<Array<ParentModel>>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: RoleModel;
  status: UserStatuses;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum UserStatuses {
  Active = 'active',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
  Ignored = 'ignored',
  Pending = 'pending'
}

export type AnecdotalFieldsFragment = { __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null };

export type CreateAnecdotalTypeMutationVariables = Exact<{
  data: AnecdotalTypeInput;
}>;


export type CreateAnecdotalTypeMutation = { __typename?: 'Mutation', createAnecdotalType: boolean };

export type RemoveAnecdotalTypesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveAnecdotalTypesMutation = { __typename?: 'Mutation', removeAnecdotalTypes: boolean };

export type ReactivateAnecdotalTypesMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateAnecdotalTypesMutation = { __typename?: 'Mutation', reactivateAnecdotalTypes: boolean };

export type GetAnecdotalTypesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAnecdotalTypesQuery = { __typename?: 'Query', getAnecdotalTypes: { __typename?: 'AnecdotalTypePaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'AnecdotalTypeModel', id: string, name: string, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null }> | null } };

export type AttendanceStatusFieldsFragment = { __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean };

export type CreateAttendanceStatusMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: AttendanceStatusInput;
}>;


export type CreateAttendanceStatusMutation = { __typename?: 'Mutation', createAttendanceStatus: { __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean } };

export type UpdateAttendanceStatusMutationVariables = Exact<{
  id: Scalars['String'];
  data: AttendanceStatusInput;
}>;


export type UpdateAttendanceStatusMutation = { __typename?: 'Mutation', updateAttendanceStatus: { __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean } };

export type ReorderAttendanceStatusesMutationVariables = Exact<{
  id: Scalars['String'];
  secondId: Scalars['String'];
}>;


export type ReorderAttendanceStatusesMutation = { __typename?: 'Mutation', reorderAttendanceStatuses: boolean };

export type ReactivateAttendanceStatusMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateAttendanceStatusMutation = { __typename?: 'Mutation', reactivateAttendanceStatus: boolean };

export type RemoveAttendanceStatusMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveAttendanceStatusMutation = { __typename?: 'Mutation', removeAttendanceStatus: boolean };

export type GetAttendanceStatusesQueryVariables = Exact<{
  districtId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAttendanceStatusesQuery = { __typename?: 'Query', getAttendanceStatuses: Array<{ __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean }> };

export type GetAttendanceStatusQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetAttendanceStatusQuery = { __typename?: 'Query', getAttendanceStatus: { __typename?: 'AttendanceStatusObject', id: string, name: string, description?: string | null, shortName?: string | null, color: string, isDeleted: boolean } };

export type DistrictAdminAuthMutationVariables = Exact<{
  data: DistrictAdminAuthInput;
}>;


export type DistrictAdminAuthMutation = { __typename?: 'Mutation', districtAdminAuth: { __typename?: 'LoginModel', token: string } };

export type ForgottenPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;


export type ForgottenPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type CleverPracticeSchoolFieldsFragment = { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type CleverSchoolFieldsFragment = { __typename?: 'CleverSchoolModel', name: string, cleverId: string, lastSyncedAt?: any | null, practiceStatus: ProviderStatusEnum, practiceSchool?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null };

export type StoreCleverSchoolsMutationVariables = Exact<{
  data: CleverSchoolsInput;
}>;


export type StoreCleverSchoolsMutation = { __typename?: 'Mutation', storeCleverSchools: boolean };

export type GetCleverSchoolsQueryVariables = Exact<{
  districtId: Scalars['String'];
  status: ProviderStatusEnum;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetCleverSchoolsQuery = { __typename?: 'Query', getCleverSchools: Array<{ __typename?: 'CleverSchoolModel', name: string, cleverId: string, lastSyncedAt?: any | null, practiceStatus: ProviderStatusEnum, practiceSchool?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null }> };

export type CleverPracticeTermFieldsFragment = { __typename?: 'TermModel', id: string, name: string, isDeleted: boolean };

export type CleverTermFieldsFragment = { __typename?: 'CleverTermModel', name: string, fromDate: any, toDate: any, cleverId: string, lastSyncedAt?: any | null, practiceStatus: ProviderStatusEnum, practiceTerm?: { __typename?: 'TermModel', id: string, name: string, isDeleted: boolean } | null };

export type StoreCleverTermsMutationVariables = Exact<{
  data: CleverTermsInput;
}>;


export type StoreCleverTermsMutation = { __typename?: 'Mutation', storeCleverTerms: boolean };

export type GetCleverTermsQueryVariables = Exact<{
  districtId: Scalars['String'];
  status: ProviderStatusEnum;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetCleverTermsQuery = { __typename?: 'Query', getCleverTerms: Array<{ __typename?: 'CleverTermModel', name: string, fromDate: any, toDate: any, cleverId: string, lastSyncedAt?: any | null, practiceStatus: ProviderStatusEnum, practiceTerm?: { __typename?: 'TermModel', id: string, name: string, isDeleted: boolean } | null }> };

export type RawDistrictFieldsFragment = { __typename?: 'DistrictModel', id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type ExtendedDistrictFieldsFragment = { __typename?: 'DistrictModel', schoolsCount?: number | null, usersCount?: number | null, id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type CreateDistrictMutationVariables = Exact<{
  data: DistrictInput;
}>;


export type CreateDistrictMutation = { __typename?: 'Mutation', createDistrict: { __typename?: 'DistrictModel', id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type UpdateDistrictMutationVariables = Exact<{
  id: Scalars['String'];
  data: DistrictInput;
}>;


export type UpdateDistrictMutation = { __typename?: 'Mutation', updateDistrict: { __typename?: 'DistrictModel', id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type ReactivateDistrictsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateDistrictsMutation = { __typename?: 'Mutation', reactivateDistricts: boolean };

export type RemoveDistrictsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveDistrictsMutation = { __typename?: 'Mutation', removeDistricts: boolean };

export type GetDistrictsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetDistrictsQuery = { __typename?: 'Query', getDistricts: { __typename?: 'DistrictPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'DistrictModel', schoolsCount?: number | null, usersCount?: number | null, id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }> | null } };

export type GetDistrictQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDistrictQuery = { __typename?: 'Query', getDistrict: { __typename?: 'DistrictModel', id: string, name: string, url: string, timezone: string, createdAt?: any | null, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, favicon?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type ListIntegrationFieldsFragment = { __typename?: 'IntegrationListModel', hasIntegration: boolean, status: boolean, type: ProviderEnum };

export type SetupIntegrationFieldsFragment = { __typename?: 'IntegrationSetupModel', hasIntegration: boolean, status: boolean, type: ProviderEnum, keys: Array<{ __typename?: 'IntegrationBlocksDTO', key: string, label: string, required: boolean, isDisable: boolean, value?: string | null }> };

export type StoreIntegrationMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: IntegrationInput;
}>;


export type StoreIntegrationMutation = { __typename?: 'Mutation', storeIntegration: { __typename?: 'IntegrationSetupModel', hasIntegration: boolean, status: boolean, type: ProviderEnum, keys: Array<{ __typename?: 'IntegrationBlocksDTO', key: string, label: string, required: boolean, isDisable: boolean, value?: string | null }> } };

export type ToggleIntegrationMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: ToggleIntegrationInput;
}>;


export type ToggleIntegrationMutation = { __typename?: 'Mutation', toggleIntegration: { __typename?: 'IntegrationSetupModel', hasIntegration: boolean, status: boolean, type: ProviderEnum, keys: Array<{ __typename?: 'IntegrationBlocksDTO', key: string, label: string, required: boolean, isDisable: boolean, value?: string | null }> } };

export type RemoveIntegrationMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: ToggleIntegrationInput;
}>;


export type RemoveIntegrationMutation = { __typename?: 'Mutation', removeIntegration: boolean };

export type GetIntegrationsQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type GetIntegrationsQuery = { __typename?: 'Query', getIntegrations: Array<{ __typename?: 'IntegrationListModel', hasIntegration: boolean, status: boolean, type: ProviderEnum }> };

export type GetIntegrationQueryVariables = Exact<{
  districtId: Scalars['String'];
  type: ProviderEnum;
}>;


export type GetIntegrationQuery = { __typename?: 'Query', getIntegration: { __typename?: 'IntegrationSetupModel', hasIntegration: boolean, status: boolean, type: ProviderEnum, keys: Array<{ __typename?: 'IntegrationBlocksDTO', key: string, label: string, required: boolean, isDisable: boolean, value?: string | null }> } };

export type PaginationFieldsFragment = { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number };

export type MinimalRoleFieldsFragment = { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', getRoles: Array<{ __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum }> };

export type MinimalSchoolFieldsFragment = { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type ExtendedSchoolFieldsFragment = { __typename?: 'SchoolModel', usersCount?: number | null, id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type CreateSchoolMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: SchoolInput;
}>;


export type CreateSchoolMutation = { __typename?: 'Mutation', createSchool: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type UpdateSchoolMutationVariables = Exact<{
  id: Scalars['String'];
  data: SchoolInput;
}>;


export type UpdateSchoolMutation = { __typename?: 'Mutation', updateSchool: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type ReactivateSchoolsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateSchoolsMutation = { __typename?: 'Mutation', reactivateSchools: boolean };

export type RemoveSchoolsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveSchoolsMutation = { __typename?: 'Mutation', removeSchools: boolean };

export type GetSchoolsQueryVariables = Exact<{
  districtId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSchoolsQuery = { __typename?: 'Query', getSchools: { __typename?: 'SchoolPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'SchoolModel', usersCount?: number | null, id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }> | null } };

export type GetSchoolQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetSchoolQuery = { __typename?: 'Query', getSchool: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type TermFieldsFragment = { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null };

export type CreateTermMutationVariables = Exact<{
  districtId: Scalars['String'];
  data: TermInput;
}>;


export type CreateTermMutation = { __typename?: 'Mutation', createTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null } };

export type UpdateTermMutationVariables = Exact<{
  id: Scalars['String'];
  data: TermInput;
}>;


export type UpdateTermMutation = { __typename?: 'Mutation', updateTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null } };

export type ReactivateTermsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateTermsMutation = { __typename?: 'Mutation', reactivateTerms: boolean };

export type RemoveTermsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveTermsMutation = { __typename?: 'Mutation', removeTerms: boolean };

export type GetTermsQueryVariables = Exact<{
  districtId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTermsQuery = { __typename?: 'Query', getTerms: { __typename?: 'TermPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null }> | null } };

export type GetTermQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetTermQuery = { __typename?: 'Query', getTerm: { __typename?: 'TermModel', id: string, name: string, fromDate: any, toDate: any, isDeleted: boolean, school?: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } | null } };

export type FileFieldsFragment = { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string };

export type UploadFilesMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type UploadFilesMutation = { __typename?: 'Mutation', uploadFiles: Array<{ __typename?: 'UploadFileInput', name: string, size: number, url: string }> };

export type DistrictAdminFieldsFragment = { __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null };

export type MinimalUserFieldsFragment = { __typename?: 'UserModel', id: string, email: string, firstName: string, lastName: string, grade?: string | null, phoneNumber?: string | null, isDeleted: boolean, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> };

export type CreateDistrictAdminMutationVariables = Exact<{
  data: CreateDistrictAdminInput;
}>;


export type CreateDistrictAdminMutation = { __typename?: 'Mutation', createDistrictAdmin: { __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type UpdateDistrictAdminMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateDistrictAdminInput;
}>;


export type UpdateDistrictAdminMutation = { __typename?: 'Mutation', updateDistrictAdmin: { __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type RemoveDistrictAdminsMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveDistrictAdminsMutation = { __typename?: 'Mutation', removeDistrictAdmins: boolean };

export type CreateUserMutationVariables = Exact<{
  data: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserModel', id: string, email: string, firstName: string, lastName: string, grade?: string | null, phoneNumber?: string | null, isDeleted: boolean, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserModel', id: string, email: string, firstName: string, lastName: string, grade?: string | null, phoneNumber?: string | null, isDeleted: boolean, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> } };

export type ReactivateUsersMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type ReactivateUsersMutation = { __typename?: 'Mutation', reactivateUsers: boolean };

export type RemoveUsersMutationVariables = Exact<{
  data: MultipleIdsInput;
}>;


export type RemoveUsersMutation = { __typename?: 'Mutation', removeUsers: boolean };

export type GetDistrictAdminsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetDistrictAdminsQuery = { __typename?: 'Query', getDistrictAdmins: { __typename?: 'DistrictAdminPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }> | null } };

export type GetDistrictAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDistrictAdminQuery = { __typename?: 'Query', getDistrictAdmin: { __typename?: 'DistrictAdminModel', id: string, email: string, firstName: string, lastName: string, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null } };

export type GetUsersQueryVariables = Exact<{
  districtId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'UserPaginationModel', pagination: { __typename?: 'CursorPagination', endCursor?: string | null, startCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, totalCount: number }, data?: Array<{ __typename?: 'UserModel', id: string, email: string, firstName: string, lastName: string, grade?: string | null, phoneNumber?: string | null, isDeleted: boolean, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> }> | null } };

export type GetUserQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'UserModel', id: string, email: string, firstName: string, lastName: string, grade?: string | null, phoneNumber?: string | null, isDeleted: boolean, image?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, schoolAccess: Array<{ __typename?: 'UserAccessModel', status: UserStatuses, school: { __typename?: 'SchoolModel', id: string, name: string, isDeleted: boolean, createdAt?: any | null, logo?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null, background?: { __typename?: 'FileModel', id?: string | null, name: string, size: number, url: string } | null }, role: { __typename?: 'RoleModel', id: string, name: string, type: RoleTypeEnum } }> } };

export const FileFieldsFragmentDoc = gql`
    fragment FileFields on FileModel {
  id
  name
  size
  url
}
    `;
export const AnecdotalFieldsFragmentDoc = gql`
    fragment AnecdotalFields on AnecdotalTypeModel {
  id
  name
  isDeleted
  school {
    id
    name
    isDeleted
    logo {
      ...FileFields
    }
  }
}
    ${FileFieldsFragmentDoc}`;
export const AttendanceStatusFieldsFragmentDoc = gql`
    fragment AttendanceStatusFields on AttendanceStatusObject {
  id
  name
  description
  shortName
  color
  isDeleted
}
    `;
export const CleverPracticeSchoolFieldsFragmentDoc = gql`
    fragment CleverPracticeSchoolFields on SchoolModel {
  id
  name
  logo {
    ...FileFields
  }
  isDeleted
}
    ${FileFieldsFragmentDoc}`;
export const CleverSchoolFieldsFragmentDoc = gql`
    fragment CleverSchoolFields on CleverSchoolModel {
  name
  cleverId
  lastSyncedAt
  practiceStatus
  practiceSchool {
    ...CleverPracticeSchoolFields
  }
}
    ${CleverPracticeSchoolFieldsFragmentDoc}`;
export const CleverPracticeTermFieldsFragmentDoc = gql`
    fragment CleverPracticeTermFields on TermModel {
  id
  name
  isDeleted
}
    `;
export const CleverTermFieldsFragmentDoc = gql`
    fragment CleverTermFields on CleverTermModel {
  name
  fromDate
  toDate
  cleverId
  lastSyncedAt
  practiceStatus
  practiceTerm {
    ...CleverPracticeTermFields
  }
}
    ${CleverPracticeTermFieldsFragmentDoc}`;
export const RawDistrictFieldsFragmentDoc = gql`
    fragment RawDistrictFields on DistrictModel {
  id
  name
  url
  timezone
  createdAt
  logo {
    ...FileFields
  }
  favicon {
    ...FileFields
  }
  background {
    ...FileFields
  }
  isDeleted
}
    ${FileFieldsFragmentDoc}`;
export const ExtendedDistrictFieldsFragmentDoc = gql`
    fragment ExtendedDistrictFields on DistrictModel {
  ...RawDistrictFields
  schoolsCount
  usersCount
}
    ${RawDistrictFieldsFragmentDoc}`;
export const ListIntegrationFieldsFragmentDoc = gql`
    fragment ListIntegrationFields on IntegrationListModel {
  hasIntegration
  status
  type
}
    `;
export const SetupIntegrationFieldsFragmentDoc = gql`
    fragment SetupIntegrationFields on IntegrationSetupModel {
  hasIntegration
  status
  type
  keys {
    key
    label
    required
    isDisable
    value
  }
}
    `;
export const PaginationFieldsFragmentDoc = gql`
    fragment PaginationFields on CursorPagination {
  endCursor
  startCursor
  hasNextPage
  hasPreviousPage
  totalCount
}
    `;
export const MinimalSchoolFieldsFragmentDoc = gql`
    fragment MinimalSchoolFields on SchoolModel {
  id
  name
  logo {
    ...FileFields
  }
  background {
    ...FileFields
  }
  isDeleted
  createdAt
}
    ${FileFieldsFragmentDoc}`;
export const ExtendedSchoolFieldsFragmentDoc = gql`
    fragment ExtendedSchoolFields on SchoolModel {
  ...MinimalSchoolFields
  usersCount
}
    ${MinimalSchoolFieldsFragmentDoc}`;
export const TermFieldsFragmentDoc = gql`
    fragment TermFields on TermModel {
  id
  name
  fromDate
  toDate
  school {
    id
    name
    isDeleted
    logo {
      ...FileFields
    }
  }
  isDeleted
}
    ${FileFieldsFragmentDoc}`;
export const DistrictAdminFieldsFragmentDoc = gql`
    fragment DistrictAdminFields on DistrictAdminModel {
  id
  email
  firstName
  lastName
  image {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export const MinimalRoleFieldsFragmentDoc = gql`
    fragment MinimalRoleFields on RoleModel {
  id
  name
  type
}
    `;
export const MinimalUserFieldsFragmentDoc = gql`
    fragment MinimalUserFields on UserModel {
  id
  email
  firstName
  lastName
  grade
  phoneNumber
  image {
    ...FileFields
  }
  schoolAccess {
    school {
      ...MinimalSchoolFields
    }
    role {
      ...MinimalRoleFields
    }
    status
  }
  isDeleted
}
    ${FileFieldsFragmentDoc}
${MinimalSchoolFieldsFragmentDoc}
${MinimalRoleFieldsFragmentDoc}`;
export const CreateAnecdotalTypeDocument = gql`
    mutation createAnecdotalType($data: AnecdotalTypeInput!) {
  createAnecdotalType(input: $data)
}
    `;
export type CreateAnecdotalTypeMutationFn = Apollo.MutationFunction<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>;

/**
 * __useCreateAnecdotalTypeMutation__
 *
 * To run a mutation, you first call `useCreateAnecdotalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnecdotalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnecdotalTypeMutation, { data, loading, error }] = useCreateAnecdotalTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAnecdotalTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>(CreateAnecdotalTypeDocument, options);
      }
export type CreateAnecdotalTypeMutationHookResult = ReturnType<typeof useCreateAnecdotalTypeMutation>;
export type CreateAnecdotalTypeMutationResult = Apollo.MutationResult<CreateAnecdotalTypeMutation>;
export type CreateAnecdotalTypeMutationOptions = Apollo.BaseMutationOptions<CreateAnecdotalTypeMutation, CreateAnecdotalTypeMutationVariables>;
export const RemoveAnecdotalTypesDocument = gql`
    mutation removeAnecdotalTypes($data: MultipleIdsInput!) {
  removeAnecdotalTypes(input: $data)
}
    `;
export type RemoveAnecdotalTypesMutationFn = Apollo.MutationFunction<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>;

/**
 * __useRemoveAnecdotalTypesMutation__
 *
 * To run a mutation, you first call `useRemoveAnecdotalTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnecdotalTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnecdotalTypesMutation, { data, loading, error }] = useRemoveAnecdotalTypesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAnecdotalTypesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>(RemoveAnecdotalTypesDocument, options);
      }
export type RemoveAnecdotalTypesMutationHookResult = ReturnType<typeof useRemoveAnecdotalTypesMutation>;
export type RemoveAnecdotalTypesMutationResult = Apollo.MutationResult<RemoveAnecdotalTypesMutation>;
export type RemoveAnecdotalTypesMutationOptions = Apollo.BaseMutationOptions<RemoveAnecdotalTypesMutation, RemoveAnecdotalTypesMutationVariables>;
export const ReactivateAnecdotalTypesDocument = gql`
    mutation reactivateAnecdotalTypes($data: MultipleIdsInput!) {
  reactivateAnecdotalTypes(input: $data)
}
    `;
export type ReactivateAnecdotalTypesMutationFn = Apollo.MutationFunction<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>;

/**
 * __useReactivateAnecdotalTypesMutation__
 *
 * To run a mutation, you first call `useReactivateAnecdotalTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateAnecdotalTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateAnecdotalTypesMutation, { data, loading, error }] = useReactivateAnecdotalTypesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateAnecdotalTypesMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>(ReactivateAnecdotalTypesDocument, options);
      }
export type ReactivateAnecdotalTypesMutationHookResult = ReturnType<typeof useReactivateAnecdotalTypesMutation>;
export type ReactivateAnecdotalTypesMutationResult = Apollo.MutationResult<ReactivateAnecdotalTypesMutation>;
export type ReactivateAnecdotalTypesMutationOptions = Apollo.BaseMutationOptions<ReactivateAnecdotalTypesMutation, ReactivateAnecdotalTypesMutationVariables>;
export const GetAnecdotalTypesDocument = gql`
    query getAnecdotalTypes($after: String, $before: String, $districtId: String!, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getAnecdotalTypes(
    after: $after
    before: $before
    limit: $limit
    search: $search
    districtId: $districtId
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...AnecdotalFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${AnecdotalFieldsFragmentDoc}`;

/**
 * __useGetAnecdotalTypesQuery__
 *
 * To run a query within a React component, call `useGetAnecdotalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnecdotalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnecdotalTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      districtId: // value for 'districtId'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetAnecdotalTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>(GetAnecdotalTypesDocument, options);
      }
export function useGetAnecdotalTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>(GetAnecdotalTypesDocument, options);
        }
export type GetAnecdotalTypesQueryHookResult = ReturnType<typeof useGetAnecdotalTypesQuery>;
export type GetAnecdotalTypesLazyQueryHookResult = ReturnType<typeof useGetAnecdotalTypesLazyQuery>;
export type GetAnecdotalTypesQueryResult = Apollo.QueryResult<GetAnecdotalTypesQuery, GetAnecdotalTypesQueryVariables>;
export const CreateAttendanceStatusDocument = gql`
    mutation createAttendanceStatus($districtId: String!, $data: AttendanceStatusInput!) {
  createAttendanceStatus(districtId: $districtId, input: $data) {
    ...AttendanceStatusFields
  }
}
    ${AttendanceStatusFieldsFragmentDoc}`;
export type CreateAttendanceStatusMutationFn = Apollo.MutationFunction<CreateAttendanceStatusMutation, CreateAttendanceStatusMutationVariables>;

/**
 * __useCreateAttendanceStatusMutation__
 *
 * To run a mutation, you first call `useCreateAttendanceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttendanceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttendanceStatusMutation, { data, loading, error }] = useCreateAttendanceStatusMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAttendanceStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttendanceStatusMutation, CreateAttendanceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttendanceStatusMutation, CreateAttendanceStatusMutationVariables>(CreateAttendanceStatusDocument, options);
      }
export type CreateAttendanceStatusMutationHookResult = ReturnType<typeof useCreateAttendanceStatusMutation>;
export type CreateAttendanceStatusMutationResult = Apollo.MutationResult<CreateAttendanceStatusMutation>;
export type CreateAttendanceStatusMutationOptions = Apollo.BaseMutationOptions<CreateAttendanceStatusMutation, CreateAttendanceStatusMutationVariables>;
export const UpdateAttendanceStatusDocument = gql`
    mutation updateAttendanceStatus($id: String!, $data: AttendanceStatusInput!) {
  updateAttendanceStatus(id: $id, input: $data) {
    ...AttendanceStatusFields
  }
}
    ${AttendanceStatusFieldsFragmentDoc}`;
export type UpdateAttendanceStatusMutationFn = Apollo.MutationFunction<UpdateAttendanceStatusMutation, UpdateAttendanceStatusMutationVariables>;

/**
 * __useUpdateAttendanceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceStatusMutation, { data, loading, error }] = useUpdateAttendanceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAttendanceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttendanceStatusMutation, UpdateAttendanceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttendanceStatusMutation, UpdateAttendanceStatusMutationVariables>(UpdateAttendanceStatusDocument, options);
      }
export type UpdateAttendanceStatusMutationHookResult = ReturnType<typeof useUpdateAttendanceStatusMutation>;
export type UpdateAttendanceStatusMutationResult = Apollo.MutationResult<UpdateAttendanceStatusMutation>;
export type UpdateAttendanceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAttendanceStatusMutation, UpdateAttendanceStatusMutationVariables>;
export const ReorderAttendanceStatusesDocument = gql`
    mutation reorderAttendanceStatuses($id: String!, $secondId: String!) {
  reorderAttendanceStatuses(id: $id, secondId: $secondId)
}
    `;
export type ReorderAttendanceStatusesMutationFn = Apollo.MutationFunction<ReorderAttendanceStatusesMutation, ReorderAttendanceStatusesMutationVariables>;

/**
 * __useReorderAttendanceStatusesMutation__
 *
 * To run a mutation, you first call `useReorderAttendanceStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderAttendanceStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderAttendanceStatusesMutation, { data, loading, error }] = useReorderAttendanceStatusesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      secondId: // value for 'secondId'
 *   },
 * });
 */
export function useReorderAttendanceStatusesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderAttendanceStatusesMutation, ReorderAttendanceStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderAttendanceStatusesMutation, ReorderAttendanceStatusesMutationVariables>(ReorderAttendanceStatusesDocument, options);
      }
export type ReorderAttendanceStatusesMutationHookResult = ReturnType<typeof useReorderAttendanceStatusesMutation>;
export type ReorderAttendanceStatusesMutationResult = Apollo.MutationResult<ReorderAttendanceStatusesMutation>;
export type ReorderAttendanceStatusesMutationOptions = Apollo.BaseMutationOptions<ReorderAttendanceStatusesMutation, ReorderAttendanceStatusesMutationVariables>;
export const ReactivateAttendanceStatusDocument = gql`
    mutation reactivateAttendanceStatus($data: MultipleIdsInput!) {
  reactivateAttendanceStatus(input: $data)
}
    `;
export type ReactivateAttendanceStatusMutationFn = Apollo.MutationFunction<ReactivateAttendanceStatusMutation, ReactivateAttendanceStatusMutationVariables>;

/**
 * __useReactivateAttendanceStatusMutation__
 *
 * To run a mutation, you first call `useReactivateAttendanceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateAttendanceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateAttendanceStatusMutation, { data, loading, error }] = useReactivateAttendanceStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateAttendanceStatusMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateAttendanceStatusMutation, ReactivateAttendanceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateAttendanceStatusMutation, ReactivateAttendanceStatusMutationVariables>(ReactivateAttendanceStatusDocument, options);
      }
export type ReactivateAttendanceStatusMutationHookResult = ReturnType<typeof useReactivateAttendanceStatusMutation>;
export type ReactivateAttendanceStatusMutationResult = Apollo.MutationResult<ReactivateAttendanceStatusMutation>;
export type ReactivateAttendanceStatusMutationOptions = Apollo.BaseMutationOptions<ReactivateAttendanceStatusMutation, ReactivateAttendanceStatusMutationVariables>;
export const RemoveAttendanceStatusDocument = gql`
    mutation removeAttendanceStatus($data: MultipleIdsInput!) {
  removeAttendanceStatus(input: $data)
}
    `;
export type RemoveAttendanceStatusMutationFn = Apollo.MutationFunction<RemoveAttendanceStatusMutation, RemoveAttendanceStatusMutationVariables>;

/**
 * __useRemoveAttendanceStatusMutation__
 *
 * To run a mutation, you first call `useRemoveAttendanceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttendanceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttendanceStatusMutation, { data, loading, error }] = useRemoveAttendanceStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAttendanceStatusMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAttendanceStatusMutation, RemoveAttendanceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAttendanceStatusMutation, RemoveAttendanceStatusMutationVariables>(RemoveAttendanceStatusDocument, options);
      }
export type RemoveAttendanceStatusMutationHookResult = ReturnType<typeof useRemoveAttendanceStatusMutation>;
export type RemoveAttendanceStatusMutationResult = Apollo.MutationResult<RemoveAttendanceStatusMutation>;
export type RemoveAttendanceStatusMutationOptions = Apollo.BaseMutationOptions<RemoveAttendanceStatusMutation, RemoveAttendanceStatusMutationVariables>;
export const GetAttendanceStatusesDocument = gql`
    query getAttendanceStatuses($districtId: String!, $search: String, $deleted: Boolean) {
  getAttendanceStatuses(
    districtId: $districtId
    search: $search
    deleted: $deleted
  ) {
    ...AttendanceStatusFields
  }
}
    ${AttendanceStatusFieldsFragmentDoc}`;

/**
 * __useGetAttendanceStatusesQuery__
 *
 * To run a query within a React component, call `useGetAttendanceStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceStatusesQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetAttendanceStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>(GetAttendanceStatusesDocument, options);
      }
export function useGetAttendanceStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>(GetAttendanceStatusesDocument, options);
        }
export type GetAttendanceStatusesQueryHookResult = ReturnType<typeof useGetAttendanceStatusesQuery>;
export type GetAttendanceStatusesLazyQueryHookResult = ReturnType<typeof useGetAttendanceStatusesLazyQuery>;
export type GetAttendanceStatusesQueryResult = Apollo.QueryResult<GetAttendanceStatusesQuery, GetAttendanceStatusesQueryVariables>;
export const GetAttendanceStatusDocument = gql`
    query getAttendanceStatus($districtId: String!, $id: String!) {
  getAttendanceStatus(districtId: $districtId, id: $id) {
    ...AttendanceStatusFields
  }
}
    ${AttendanceStatusFieldsFragmentDoc}`;

/**
 * __useGetAttendanceStatusQuery__
 *
 * To run a query within a React component, call `useGetAttendanceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceStatusQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttendanceStatusQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceStatusQuery, GetAttendanceStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceStatusQuery, GetAttendanceStatusQueryVariables>(GetAttendanceStatusDocument, options);
      }
export function useGetAttendanceStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceStatusQuery, GetAttendanceStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceStatusQuery, GetAttendanceStatusQueryVariables>(GetAttendanceStatusDocument, options);
        }
export type GetAttendanceStatusQueryHookResult = ReturnType<typeof useGetAttendanceStatusQuery>;
export type GetAttendanceStatusLazyQueryHookResult = ReturnType<typeof useGetAttendanceStatusLazyQuery>;
export type GetAttendanceStatusQueryResult = Apollo.QueryResult<GetAttendanceStatusQuery, GetAttendanceStatusQueryVariables>;
export const DistrictAdminAuthDocument = gql`
    mutation districtAdminAuth($data: DistrictAdminAuthInput!) {
  districtAdminAuth(input: $data) {
    token
  }
}
    `;
export type DistrictAdminAuthMutationFn = Apollo.MutationFunction<DistrictAdminAuthMutation, DistrictAdminAuthMutationVariables>;

/**
 * __useDistrictAdminAuthMutation__
 *
 * To run a mutation, you first call `useDistrictAdminAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistrictAdminAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [districtAdminAuthMutation, { data, loading, error }] = useDistrictAdminAuthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDistrictAdminAuthMutation(baseOptions?: Apollo.MutationHookOptions<DistrictAdminAuthMutation, DistrictAdminAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DistrictAdminAuthMutation, DistrictAdminAuthMutationVariables>(DistrictAdminAuthDocument, options);
      }
export type DistrictAdminAuthMutationHookResult = ReturnType<typeof useDistrictAdminAuthMutation>;
export type DistrictAdminAuthMutationResult = Apollo.MutationResult<DistrictAdminAuthMutation>;
export type DistrictAdminAuthMutationOptions = Apollo.BaseMutationOptions<DistrictAdminAuthMutation, DistrictAdminAuthMutationVariables>;
export const ForgottenPasswordDocument = gql`
    mutation forgottenPassword($data: ForgotPasswordInput!) {
  forgotPassword(input: $data)
}
    `;
export type ForgottenPasswordMutationFn = Apollo.MutationFunction<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;

/**
 * __useForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordMutation, { data, loading, error }] = useForgottenPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgottenPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>(ForgottenPasswordDocument, options);
      }
export type ForgottenPasswordMutationHookResult = ReturnType<typeof useForgottenPasswordMutation>;
export type ForgottenPasswordMutationResult = Apollo.MutationResult<ForgottenPasswordMutation>;
export type ForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<ForgottenPasswordMutation, ForgottenPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($data: ResetPasswordInput!) {
  resetPassword(input: $data)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...DistrictAdminFields
  }
}
    ${DistrictAdminFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const StoreCleverSchoolsDocument = gql`
    mutation storeCleverSchools($data: CleverSchoolsInput!) {
  storeCleverSchools(input: $data)
}
    `;
export type StoreCleverSchoolsMutationFn = Apollo.MutationFunction<StoreCleverSchoolsMutation, StoreCleverSchoolsMutationVariables>;

/**
 * __useStoreCleverSchoolsMutation__
 *
 * To run a mutation, you first call `useStoreCleverSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreCleverSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeCleverSchoolsMutation, { data, loading, error }] = useStoreCleverSchoolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreCleverSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<StoreCleverSchoolsMutation, StoreCleverSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreCleverSchoolsMutation, StoreCleverSchoolsMutationVariables>(StoreCleverSchoolsDocument, options);
      }
export type StoreCleverSchoolsMutationHookResult = ReturnType<typeof useStoreCleverSchoolsMutation>;
export type StoreCleverSchoolsMutationResult = Apollo.MutationResult<StoreCleverSchoolsMutation>;
export type StoreCleverSchoolsMutationOptions = Apollo.BaseMutationOptions<StoreCleverSchoolsMutation, StoreCleverSchoolsMutationVariables>;
export const GetCleverSchoolsDocument = gql`
    query getCleverSchools($districtId: String!, $status: ProviderStatusEnum!, $search: String) {
  getCleverSchools(districtId: $districtId, status: $status, search: $search) {
    ...CleverSchoolFields
  }
}
    ${CleverSchoolFieldsFragmentDoc}`;

/**
 * __useGetCleverSchoolsQuery__
 *
 * To run a query within a React component, call `useGetCleverSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverSchoolsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCleverSchoolsQuery(baseOptions: Apollo.QueryHookOptions<GetCleverSchoolsQuery, GetCleverSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCleverSchoolsQuery, GetCleverSchoolsQueryVariables>(GetCleverSchoolsDocument, options);
      }
export function useGetCleverSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCleverSchoolsQuery, GetCleverSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCleverSchoolsQuery, GetCleverSchoolsQueryVariables>(GetCleverSchoolsDocument, options);
        }
export type GetCleverSchoolsQueryHookResult = ReturnType<typeof useGetCleverSchoolsQuery>;
export type GetCleverSchoolsLazyQueryHookResult = ReturnType<typeof useGetCleverSchoolsLazyQuery>;
export type GetCleverSchoolsQueryResult = Apollo.QueryResult<GetCleverSchoolsQuery, GetCleverSchoolsQueryVariables>;
export const StoreCleverTermsDocument = gql`
    mutation storeCleverTerms($data: CleverTermsInput!) {
  storeCleverTerms(input: $data)
}
    `;
export type StoreCleverTermsMutationFn = Apollo.MutationFunction<StoreCleverTermsMutation, StoreCleverTermsMutationVariables>;

/**
 * __useStoreCleverTermsMutation__
 *
 * To run a mutation, you first call `useStoreCleverTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreCleverTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeCleverTermsMutation, { data, loading, error }] = useStoreCleverTermsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreCleverTermsMutation(baseOptions?: Apollo.MutationHookOptions<StoreCleverTermsMutation, StoreCleverTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreCleverTermsMutation, StoreCleverTermsMutationVariables>(StoreCleverTermsDocument, options);
      }
export type StoreCleverTermsMutationHookResult = ReturnType<typeof useStoreCleverTermsMutation>;
export type StoreCleverTermsMutationResult = Apollo.MutationResult<StoreCleverTermsMutation>;
export type StoreCleverTermsMutationOptions = Apollo.BaseMutationOptions<StoreCleverTermsMutation, StoreCleverTermsMutationVariables>;
export const GetCleverTermsDocument = gql`
    query getCleverTerms($districtId: String!, $status: ProviderStatusEnum!, $search: String) {
  getCleverTerms(districtId: $districtId, status: $status, search: $search) {
    ...CleverTermFields
  }
}
    ${CleverTermFieldsFragmentDoc}`;

/**
 * __useGetCleverTermsQuery__
 *
 * To run a query within a React component, call `useGetCleverTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverTermsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCleverTermsQuery(baseOptions: Apollo.QueryHookOptions<GetCleverTermsQuery, GetCleverTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCleverTermsQuery, GetCleverTermsQueryVariables>(GetCleverTermsDocument, options);
      }
export function useGetCleverTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCleverTermsQuery, GetCleverTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCleverTermsQuery, GetCleverTermsQueryVariables>(GetCleverTermsDocument, options);
        }
export type GetCleverTermsQueryHookResult = ReturnType<typeof useGetCleverTermsQuery>;
export type GetCleverTermsLazyQueryHookResult = ReturnType<typeof useGetCleverTermsLazyQuery>;
export type GetCleverTermsQueryResult = Apollo.QueryResult<GetCleverTermsQuery, GetCleverTermsQueryVariables>;
export const CreateDistrictDocument = gql`
    mutation createDistrict($data: DistrictInput!) {
  createDistrict(input: $data) {
    ...RawDistrictFields
  }
}
    ${RawDistrictFieldsFragmentDoc}`;
export type CreateDistrictMutationFn = Apollo.MutationFunction<CreateDistrictMutation, CreateDistrictMutationVariables>;

/**
 * __useCreateDistrictMutation__
 *
 * To run a mutation, you first call `useCreateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistrictMutation, { data, loading, error }] = useCreateDistrictMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDistrictMutation(baseOptions?: Apollo.MutationHookOptions<CreateDistrictMutation, CreateDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDistrictMutation, CreateDistrictMutationVariables>(CreateDistrictDocument, options);
      }
export type CreateDistrictMutationHookResult = ReturnType<typeof useCreateDistrictMutation>;
export type CreateDistrictMutationResult = Apollo.MutationResult<CreateDistrictMutation>;
export type CreateDistrictMutationOptions = Apollo.BaseMutationOptions<CreateDistrictMutation, CreateDistrictMutationVariables>;
export const UpdateDistrictDocument = gql`
    mutation updateDistrict($id: String!, $data: DistrictInput!) {
  updateDistrict(id: $id, input: $data) {
    ...RawDistrictFields
  }
}
    ${RawDistrictFieldsFragmentDoc}`;
export type UpdateDistrictMutationFn = Apollo.MutationFunction<UpdateDistrictMutation, UpdateDistrictMutationVariables>;

/**
 * __useUpdateDistrictMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictMutation, { data, loading, error }] = useUpdateDistrictMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDistrictMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDistrictMutation, UpdateDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDistrictMutation, UpdateDistrictMutationVariables>(UpdateDistrictDocument, options);
      }
export type UpdateDistrictMutationHookResult = ReturnType<typeof useUpdateDistrictMutation>;
export type UpdateDistrictMutationResult = Apollo.MutationResult<UpdateDistrictMutation>;
export type UpdateDistrictMutationOptions = Apollo.BaseMutationOptions<UpdateDistrictMutation, UpdateDistrictMutationVariables>;
export const ReactivateDistrictsDocument = gql`
    mutation reactivateDistricts($data: MultipleIdsInput!) {
  reactivateDistricts(input: $data)
}
    `;
export type ReactivateDistrictsMutationFn = Apollo.MutationFunction<ReactivateDistrictsMutation, ReactivateDistrictsMutationVariables>;

/**
 * __useReactivateDistrictsMutation__
 *
 * To run a mutation, you first call `useReactivateDistrictsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateDistrictsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateDistrictsMutation, { data, loading, error }] = useReactivateDistrictsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateDistrictsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateDistrictsMutation, ReactivateDistrictsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateDistrictsMutation, ReactivateDistrictsMutationVariables>(ReactivateDistrictsDocument, options);
      }
export type ReactivateDistrictsMutationHookResult = ReturnType<typeof useReactivateDistrictsMutation>;
export type ReactivateDistrictsMutationResult = Apollo.MutationResult<ReactivateDistrictsMutation>;
export type ReactivateDistrictsMutationOptions = Apollo.BaseMutationOptions<ReactivateDistrictsMutation, ReactivateDistrictsMutationVariables>;
export const RemoveDistrictsDocument = gql`
    mutation removeDistricts($data: MultipleIdsInput!) {
  removeDistricts(input: $data)
}
    `;
export type RemoveDistrictsMutationFn = Apollo.MutationFunction<RemoveDistrictsMutation, RemoveDistrictsMutationVariables>;

/**
 * __useRemoveDistrictsMutation__
 *
 * To run a mutation, you first call `useRemoveDistrictsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDistrictsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDistrictsMutation, { data, loading, error }] = useRemoveDistrictsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveDistrictsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDistrictsMutation, RemoveDistrictsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDistrictsMutation, RemoveDistrictsMutationVariables>(RemoveDistrictsDocument, options);
      }
export type RemoveDistrictsMutationHookResult = ReturnType<typeof useRemoveDistrictsMutation>;
export type RemoveDistrictsMutationResult = Apollo.MutationResult<RemoveDistrictsMutation>;
export type RemoveDistrictsMutationOptions = Apollo.BaseMutationOptions<RemoveDistrictsMutation, RemoveDistrictsMutationVariables>;
export const GetDistrictsDocument = gql`
    query getDistricts($after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getDistricts(
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...ExtendedDistrictFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${ExtendedDistrictFieldsFragmentDoc}`;

/**
 * __useGetDistrictsQuery__
 *
 * To run a query within a React component, call `useGetDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetDistrictsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistrictsQuery, GetDistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(GetDistrictsDocument, options);
      }
export function useGetDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictsQuery, GetDistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(GetDistrictsDocument, options);
        }
export type GetDistrictsQueryHookResult = ReturnType<typeof useGetDistrictsQuery>;
export type GetDistrictsLazyQueryHookResult = ReturnType<typeof useGetDistrictsLazyQuery>;
export type GetDistrictsQueryResult = Apollo.QueryResult<GetDistrictsQuery, GetDistrictsQueryVariables>;
export const GetDistrictDocument = gql`
    query getDistrict($id: String!) {
  getDistrict(id: $id) {
    ...RawDistrictFields
  }
}
    ${RawDistrictFieldsFragmentDoc}`;

/**
 * __useGetDistrictQuery__
 *
 * To run a query within a React component, call `useGetDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDistrictQuery(baseOptions: Apollo.QueryHookOptions<GetDistrictQuery, GetDistrictQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictQuery, GetDistrictQueryVariables>(GetDistrictDocument, options);
      }
export function useGetDistrictLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictQuery, GetDistrictQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictQuery, GetDistrictQueryVariables>(GetDistrictDocument, options);
        }
export type GetDistrictQueryHookResult = ReturnType<typeof useGetDistrictQuery>;
export type GetDistrictLazyQueryHookResult = ReturnType<typeof useGetDistrictLazyQuery>;
export type GetDistrictQueryResult = Apollo.QueryResult<GetDistrictQuery, GetDistrictQueryVariables>;
export const StoreIntegrationDocument = gql`
    mutation storeIntegration($districtId: String!, $data: IntegrationInput!) {
  storeIntegration(districtId: $districtId, input: $data) {
    ...SetupIntegrationFields
  }
}
    ${SetupIntegrationFieldsFragmentDoc}`;
export type StoreIntegrationMutationFn = Apollo.MutationFunction<StoreIntegrationMutation, StoreIntegrationMutationVariables>;

/**
 * __useStoreIntegrationMutation__
 *
 * To run a mutation, you first call `useStoreIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeIntegrationMutation, { data, loading, error }] = useStoreIntegrationMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<StoreIntegrationMutation, StoreIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreIntegrationMutation, StoreIntegrationMutationVariables>(StoreIntegrationDocument, options);
      }
export type StoreIntegrationMutationHookResult = ReturnType<typeof useStoreIntegrationMutation>;
export type StoreIntegrationMutationResult = Apollo.MutationResult<StoreIntegrationMutation>;
export type StoreIntegrationMutationOptions = Apollo.BaseMutationOptions<StoreIntegrationMutation, StoreIntegrationMutationVariables>;
export const ToggleIntegrationDocument = gql`
    mutation toggleIntegration($districtId: String!, $data: ToggleIntegrationInput!) {
  toggleIntegration(districtId: $districtId, input: $data) {
    ...SetupIntegrationFields
  }
}
    ${SetupIntegrationFieldsFragmentDoc}`;
export type ToggleIntegrationMutationFn = Apollo.MutationFunction<ToggleIntegrationMutation, ToggleIntegrationMutationVariables>;

/**
 * __useToggleIntegrationMutation__
 *
 * To run a mutation, you first call `useToggleIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIntegrationMutation, { data, loading, error }] = useToggleIntegrationMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useToggleIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIntegrationMutation, ToggleIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIntegrationMutation, ToggleIntegrationMutationVariables>(ToggleIntegrationDocument, options);
      }
export type ToggleIntegrationMutationHookResult = ReturnType<typeof useToggleIntegrationMutation>;
export type ToggleIntegrationMutationResult = Apollo.MutationResult<ToggleIntegrationMutation>;
export type ToggleIntegrationMutationOptions = Apollo.BaseMutationOptions<ToggleIntegrationMutation, ToggleIntegrationMutationVariables>;
export const RemoveIntegrationDocument = gql`
    mutation removeIntegration($districtId: String!, $data: ToggleIntegrationInput!) {
  removeIntegration(districtId: $districtId, input: $data)
}
    `;
export type RemoveIntegrationMutationFn = Apollo.MutationFunction<RemoveIntegrationMutation, RemoveIntegrationMutationVariables>;

/**
 * __useRemoveIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIntegrationMutation, { data, loading, error }] = useRemoveIntegrationMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIntegrationMutation, RemoveIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveIntegrationMutation, RemoveIntegrationMutationVariables>(RemoveIntegrationDocument, options);
      }
export type RemoveIntegrationMutationHookResult = ReturnType<typeof useRemoveIntegrationMutation>;
export type RemoveIntegrationMutationResult = Apollo.MutationResult<RemoveIntegrationMutation>;
export type RemoveIntegrationMutationOptions = Apollo.BaseMutationOptions<RemoveIntegrationMutation, RemoveIntegrationMutationVariables>;
export const GetIntegrationsDocument = gql`
    query getIntegrations($districtId: String!) {
  getIntegrations(districtId: $districtId) {
    ...ListIntegrationFields
  }
}
    ${ListIntegrationFieldsFragmentDoc}`;

/**
 * __useGetIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useGetIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationsQuery, GetIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationsQuery, GetIntegrationsQueryVariables>(GetIntegrationsDocument, options);
      }
export function useGetIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationsQuery, GetIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationsQuery, GetIntegrationsQueryVariables>(GetIntegrationsDocument, options);
        }
export type GetIntegrationsQueryHookResult = ReturnType<typeof useGetIntegrationsQuery>;
export type GetIntegrationsLazyQueryHookResult = ReturnType<typeof useGetIntegrationsLazyQuery>;
export type GetIntegrationsQueryResult = Apollo.QueryResult<GetIntegrationsQuery, GetIntegrationsQueryVariables>;
export const GetIntegrationDocument = gql`
    query getIntegration($districtId: String!, $type: ProviderEnum!) {
  getIntegration(districtId: $districtId, type: $type) {
    ...SetupIntegrationFields
  }
}
    ${SetupIntegrationFieldsFragmentDoc}`;

/**
 * __useGetIntegrationQuery__
 *
 * To run a query within a React component, call `useGetIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationQuery, GetIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationQuery, GetIntegrationQueryVariables>(GetIntegrationDocument, options);
      }
export function useGetIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationQuery, GetIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationQuery, GetIntegrationQueryVariables>(GetIntegrationDocument, options);
        }
export type GetIntegrationQueryHookResult = ReturnType<typeof useGetIntegrationQuery>;
export type GetIntegrationLazyQueryHookResult = ReturnType<typeof useGetIntegrationLazyQuery>;
export type GetIntegrationQueryResult = Apollo.QueryResult<GetIntegrationQuery, GetIntegrationQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  getRoles {
    ...MinimalRoleFields
  }
}
    ${MinimalRoleFieldsFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const CreateSchoolDocument = gql`
    mutation createSchool($districtId: String!, $data: SchoolInput!) {
  createSchool(districtId: $districtId, input: $data) {
    ...MinimalSchoolFields
  }
}
    ${MinimalSchoolFieldsFragmentDoc}`;
export type CreateSchoolMutationFn = Apollo.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, options);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const UpdateSchoolDocument = gql`
    mutation updateSchool($id: String!, $data: SchoolInput!) {
  updateSchool(id: $id, input: $data) {
    ...MinimalSchoolFields
  }
}
    ${MinimalSchoolFieldsFragmentDoc}`;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const ReactivateSchoolsDocument = gql`
    mutation reactivateSchools($data: MultipleIdsInput!) {
  reactivateSchools(input: $data)
}
    `;
export type ReactivateSchoolsMutationFn = Apollo.MutationFunction<ReactivateSchoolsMutation, ReactivateSchoolsMutationVariables>;

/**
 * __useReactivateSchoolsMutation__
 *
 * To run a mutation, you first call `useReactivateSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSchoolsMutation, { data, loading, error }] = useReactivateSchoolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSchoolsMutation, ReactivateSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateSchoolsMutation, ReactivateSchoolsMutationVariables>(ReactivateSchoolsDocument, options);
      }
export type ReactivateSchoolsMutationHookResult = ReturnType<typeof useReactivateSchoolsMutation>;
export type ReactivateSchoolsMutationResult = Apollo.MutationResult<ReactivateSchoolsMutation>;
export type ReactivateSchoolsMutationOptions = Apollo.BaseMutationOptions<ReactivateSchoolsMutation, ReactivateSchoolsMutationVariables>;
export const RemoveSchoolsDocument = gql`
    mutation removeSchools($data: MultipleIdsInput!) {
  removeSchools(input: $data)
}
    `;
export type RemoveSchoolsMutationFn = Apollo.MutationFunction<RemoveSchoolsMutation, RemoveSchoolsMutationVariables>;

/**
 * __useRemoveSchoolsMutation__
 *
 * To run a mutation, you first call `useRemoveSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSchoolsMutation, { data, loading, error }] = useRemoveSchoolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSchoolsMutation, RemoveSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSchoolsMutation, RemoveSchoolsMutationVariables>(RemoveSchoolsDocument, options);
      }
export type RemoveSchoolsMutationHookResult = ReturnType<typeof useRemoveSchoolsMutation>;
export type RemoveSchoolsMutationResult = Apollo.MutationResult<RemoveSchoolsMutation>;
export type RemoveSchoolsMutationOptions = Apollo.BaseMutationOptions<RemoveSchoolsMutation, RemoveSchoolsMutationVariables>;
export const GetSchoolsDocument = gql`
    query getSchools($districtId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getSchools(
    districtId: $districtId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...ExtendedSchoolFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${ExtendedSchoolFieldsFragmentDoc}`;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetSchoolsQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchoolsQuery, GetSchoolsQueryVariables>;
export const GetSchoolDocument = gql`
    query getSchool($districtId: String!, $id: String!) {
  getSchool(districtId: $districtId, id: $id) {
    ...MinimalSchoolFields
  }
}
    ${MinimalSchoolFieldsFragmentDoc}`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, options);
      }
export function useGetSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, options);
        }
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<typeof useGetSchoolLazyQuery>;
export type GetSchoolQueryResult = Apollo.QueryResult<GetSchoolQuery, GetSchoolQueryVariables>;
export const CreateTermDocument = gql`
    mutation createTerm($districtId: String!, $data: TermInput!) {
  createTerm(districtId: $districtId, input: $data) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type CreateTermMutationFn = Apollo.MutationFunction<CreateTermMutation, CreateTermMutationVariables>;

/**
 * __useCreateTermMutation__
 *
 * To run a mutation, you first call `useCreateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermMutation, { data, loading, error }] = useCreateTermMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTermMutation(baseOptions?: Apollo.MutationHookOptions<CreateTermMutation, CreateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTermMutation, CreateTermMutationVariables>(CreateTermDocument, options);
      }
export type CreateTermMutationHookResult = ReturnType<typeof useCreateTermMutation>;
export type CreateTermMutationResult = Apollo.MutationResult<CreateTermMutation>;
export type CreateTermMutationOptions = Apollo.BaseMutationOptions<CreateTermMutation, CreateTermMutationVariables>;
export const UpdateTermDocument = gql`
    mutation updateTerm($id: String!, $data: TermInput!) {
  updateTerm(id: $id, input: $data) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type UpdateTermMutationFn = Apollo.MutationFunction<UpdateTermMutation, UpdateTermMutationVariables>;

/**
 * __useUpdateTermMutation__
 *
 * To run a mutation, you first call `useUpdateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermMutation, { data, loading, error }] = useUpdateTermMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTermMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermMutation, UpdateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermMutation, UpdateTermMutationVariables>(UpdateTermDocument, options);
      }
export type UpdateTermMutationHookResult = ReturnType<typeof useUpdateTermMutation>;
export type UpdateTermMutationResult = Apollo.MutationResult<UpdateTermMutation>;
export type UpdateTermMutationOptions = Apollo.BaseMutationOptions<UpdateTermMutation, UpdateTermMutationVariables>;
export const ReactivateTermsDocument = gql`
    mutation reactivateTerms($data: MultipleIdsInput!) {
  reactivateTerms(input: $data)
}
    `;
export type ReactivateTermsMutationFn = Apollo.MutationFunction<ReactivateTermsMutation, ReactivateTermsMutationVariables>;

/**
 * __useReactivateTermsMutation__
 *
 * To run a mutation, you first call `useReactivateTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTermsMutation, { data, loading, error }] = useReactivateTermsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateTermsMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateTermsMutation, ReactivateTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateTermsMutation, ReactivateTermsMutationVariables>(ReactivateTermsDocument, options);
      }
export type ReactivateTermsMutationHookResult = ReturnType<typeof useReactivateTermsMutation>;
export type ReactivateTermsMutationResult = Apollo.MutationResult<ReactivateTermsMutation>;
export type ReactivateTermsMutationOptions = Apollo.BaseMutationOptions<ReactivateTermsMutation, ReactivateTermsMutationVariables>;
export const RemoveTermsDocument = gql`
    mutation removeTerms($data: MultipleIdsInput!) {
  removeTerms(input: $data)
}
    `;
export type RemoveTermsMutationFn = Apollo.MutationFunction<RemoveTermsMutation, RemoveTermsMutationVariables>;

/**
 * __useRemoveTermsMutation__
 *
 * To run a mutation, you first call `useRemoveTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTermsMutation, { data, loading, error }] = useRemoveTermsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveTermsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTermsMutation, RemoveTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTermsMutation, RemoveTermsMutationVariables>(RemoveTermsDocument, options);
      }
export type RemoveTermsMutationHookResult = ReturnType<typeof useRemoveTermsMutation>;
export type RemoveTermsMutationResult = Apollo.MutationResult<RemoveTermsMutation>;
export type RemoveTermsMutationOptions = Apollo.BaseMutationOptions<RemoveTermsMutation, RemoveTermsMutationVariables>;
export const GetTermsDocument = gql`
    query getTerms($districtId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getTerms(
    districtId: $districtId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...TermFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${TermFieldsFragmentDoc}`;

/**
 * __useGetTermsQuery__
 *
 * To run a query within a React component, call `useGetTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetTermsQuery(baseOptions: Apollo.QueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
      }
export function useGetTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
        }
export type GetTermsQueryHookResult = ReturnType<typeof useGetTermsQuery>;
export type GetTermsLazyQueryHookResult = ReturnType<typeof useGetTermsLazyQuery>;
export type GetTermsQueryResult = Apollo.QueryResult<GetTermsQuery, GetTermsQueryVariables>;
export const GetTermDocument = gql`
    query getTerm($districtId: String!, $id: String!) {
  getTerm(districtId: $districtId, id: $id) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;

/**
 * __useGetTermQuery__
 *
 * To run a query within a React component, call `useGetTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermQuery(baseOptions: Apollo.QueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, options);
      }
export function useGetTermLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, options);
        }
export type GetTermQueryHookResult = ReturnType<typeof useGetTermQuery>;
export type GetTermLazyQueryHookResult = ReturnType<typeof useGetTermLazyQuery>;
export type GetTermQueryResult = Apollo.QueryResult<GetTermQuery, GetTermQueryVariables>;
export const UploadFilesDocument = gql`
    mutation uploadFiles($files: [Upload!]!) {
  uploadFiles(files: $files) {
    name
    size
    url
  }
}
    `;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, options);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const CreateDistrictAdminDocument = gql`
    mutation createDistrictAdmin($data: CreateDistrictAdminInput!) {
  createDistrictAdmin(input: $data) {
    ...DistrictAdminFields
  }
}
    ${DistrictAdminFieldsFragmentDoc}`;
export type CreateDistrictAdminMutationFn = Apollo.MutationFunction<CreateDistrictAdminMutation, CreateDistrictAdminMutationVariables>;

/**
 * __useCreateDistrictAdminMutation__
 *
 * To run a mutation, you first call `useCreateDistrictAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistrictAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistrictAdminMutation, { data, loading, error }] = useCreateDistrictAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDistrictAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateDistrictAdminMutation, CreateDistrictAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDistrictAdminMutation, CreateDistrictAdminMutationVariables>(CreateDistrictAdminDocument, options);
      }
export type CreateDistrictAdminMutationHookResult = ReturnType<typeof useCreateDistrictAdminMutation>;
export type CreateDistrictAdminMutationResult = Apollo.MutationResult<CreateDistrictAdminMutation>;
export type CreateDistrictAdminMutationOptions = Apollo.BaseMutationOptions<CreateDistrictAdminMutation, CreateDistrictAdminMutationVariables>;
export const UpdateDistrictAdminDocument = gql`
    mutation updateDistrictAdmin($id: String!, $data: UpdateDistrictAdminInput!) {
  updateDistrictAdmin(id: $id, input: $data) {
    ...DistrictAdminFields
  }
}
    ${DistrictAdminFieldsFragmentDoc}`;
export type UpdateDistrictAdminMutationFn = Apollo.MutationFunction<UpdateDistrictAdminMutation, UpdateDistrictAdminMutationVariables>;

/**
 * __useUpdateDistrictAdminMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictAdminMutation, { data, loading, error }] = useUpdateDistrictAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDistrictAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDistrictAdminMutation, UpdateDistrictAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDistrictAdminMutation, UpdateDistrictAdminMutationVariables>(UpdateDistrictAdminDocument, options);
      }
export type UpdateDistrictAdminMutationHookResult = ReturnType<typeof useUpdateDistrictAdminMutation>;
export type UpdateDistrictAdminMutationResult = Apollo.MutationResult<UpdateDistrictAdminMutation>;
export type UpdateDistrictAdminMutationOptions = Apollo.BaseMutationOptions<UpdateDistrictAdminMutation, UpdateDistrictAdminMutationVariables>;
export const RemoveDistrictAdminsDocument = gql`
    mutation removeDistrictAdmins($data: MultipleIdsInput!) {
  removeDistrictAdmins(input: $data)
}
    `;
export type RemoveDistrictAdminsMutationFn = Apollo.MutationFunction<RemoveDistrictAdminsMutation, RemoveDistrictAdminsMutationVariables>;

/**
 * __useRemoveDistrictAdminsMutation__
 *
 * To run a mutation, you first call `useRemoveDistrictAdminsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDistrictAdminsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDistrictAdminsMutation, { data, loading, error }] = useRemoveDistrictAdminsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveDistrictAdminsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDistrictAdminsMutation, RemoveDistrictAdminsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDistrictAdminsMutation, RemoveDistrictAdminsMutationVariables>(RemoveDistrictAdminsDocument, options);
      }
export type RemoveDistrictAdminsMutationHookResult = ReturnType<typeof useRemoveDistrictAdminsMutation>;
export type RemoveDistrictAdminsMutationResult = Apollo.MutationResult<RemoveDistrictAdminsMutation>;
export type RemoveDistrictAdminsMutationOptions = Apollo.BaseMutationOptions<RemoveDistrictAdminsMutation, RemoveDistrictAdminsMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: UserInput!) {
  createUser(input: $data) {
    ...MinimalUserFields
  }
}
    ${MinimalUserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $data: UpdateUserInput!) {
  updateUser(id: $id, input: $data) {
    ...MinimalUserFields
  }
}
    ${MinimalUserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ReactivateUsersDocument = gql`
    mutation reactivateUsers($data: MultipleIdsInput!) {
  reactivateUsers(input: $data)
}
    `;
export type ReactivateUsersMutationFn = Apollo.MutationFunction<ReactivateUsersMutation, ReactivateUsersMutationVariables>;

/**
 * __useReactivateUsersMutation__
 *
 * To run a mutation, you first call `useReactivateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUsersMutation, { data, loading, error }] = useReactivateUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateUsersMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateUsersMutation, ReactivateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateUsersMutation, ReactivateUsersMutationVariables>(ReactivateUsersDocument, options);
      }
export type ReactivateUsersMutationHookResult = ReturnType<typeof useReactivateUsersMutation>;
export type ReactivateUsersMutationResult = Apollo.MutationResult<ReactivateUsersMutation>;
export type ReactivateUsersMutationOptions = Apollo.BaseMutationOptions<ReactivateUsersMutation, ReactivateUsersMutationVariables>;
export const RemoveUsersDocument = gql`
    mutation removeUsers($data: MultipleIdsInput!) {
  removeUsers(input: $data)
}
    `;
export type RemoveUsersMutationFn = Apollo.MutationFunction<RemoveUsersMutation, RemoveUsersMutationVariables>;

/**
 * __useRemoveUsersMutation__
 *
 * To run a mutation, you first call `useRemoveUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersMutation, { data, loading, error }] = useRemoveUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveUsersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUsersMutation, RemoveUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUsersMutation, RemoveUsersMutationVariables>(RemoveUsersDocument, options);
      }
export type RemoveUsersMutationHookResult = ReturnType<typeof useRemoveUsersMutation>;
export type RemoveUsersMutationResult = Apollo.MutationResult<RemoveUsersMutation>;
export type RemoveUsersMutationOptions = Apollo.BaseMutationOptions<RemoveUsersMutation, RemoveUsersMutationVariables>;
export const GetDistrictAdminsDocument = gql`
    query getDistrictAdmins($after: String, $before: String, $limit: Int = 10, $search: String) {
  getDistrictAdmins(
    after: $after
    before: $before
    limit: $limit
    search: $search
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...DistrictAdminFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${DistrictAdminFieldsFragmentDoc}`;

/**
 * __useGetDistrictAdminsQuery__
 *
 * To run a query within a React component, call `useGetDistrictAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictAdminsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetDistrictAdminsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistrictAdminsQuery, GetDistrictAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictAdminsQuery, GetDistrictAdminsQueryVariables>(GetDistrictAdminsDocument, options);
      }
export function useGetDistrictAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictAdminsQuery, GetDistrictAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictAdminsQuery, GetDistrictAdminsQueryVariables>(GetDistrictAdminsDocument, options);
        }
export type GetDistrictAdminsQueryHookResult = ReturnType<typeof useGetDistrictAdminsQuery>;
export type GetDistrictAdminsLazyQueryHookResult = ReturnType<typeof useGetDistrictAdminsLazyQuery>;
export type GetDistrictAdminsQueryResult = Apollo.QueryResult<GetDistrictAdminsQuery, GetDistrictAdminsQueryVariables>;
export const GetDistrictAdminDocument = gql`
    query getDistrictAdmin($id: String!) {
  getDistrictAdmin(id: $id) {
    ...DistrictAdminFields
  }
}
    ${DistrictAdminFieldsFragmentDoc}`;

/**
 * __useGetDistrictAdminQuery__
 *
 * To run a query within a React component, call `useGetDistrictAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDistrictAdminQuery(baseOptions: Apollo.QueryHookOptions<GetDistrictAdminQuery, GetDistrictAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictAdminQuery, GetDistrictAdminQueryVariables>(GetDistrictAdminDocument, options);
      }
export function useGetDistrictAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictAdminQuery, GetDistrictAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictAdminQuery, GetDistrictAdminQueryVariables>(GetDistrictAdminDocument, options);
        }
export type GetDistrictAdminQueryHookResult = ReturnType<typeof useGetDistrictAdminQuery>;
export type GetDistrictAdminLazyQueryHookResult = ReturnType<typeof useGetDistrictAdminLazyQuery>;
export type GetDistrictAdminQueryResult = Apollo.QueryResult<GetDistrictAdminQuery, GetDistrictAdminQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($districtId: String!, $after: String, $before: String, $limit: Int = 10, $search: String, $deleted: Boolean) {
  getUsers(
    districtId: $districtId
    after: $after
    before: $before
    limit: $limit
    search: $search
    deleted: $deleted
  ) {
    pagination {
      ...PaginationFields
    }
    data {
      ...MinimalUserFields
    }
  }
}
    ${PaginationFieldsFragmentDoc}
${MinimalUserFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query getUser($districtId: String!, $id: String!) {
  getUser(districtId: $districtId, id: $id) {
    ...MinimalUserFields
  }
}
    ${MinimalUserFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;