import styled, { css } from "styled-components";
import TitleWrapper from "../title/title.styles";

import {
  ISpace,
  IAlign,
  IDownResponsive,
} from "../../common/interfaces/theme-options.interfaces";
import { down, spacing, textAlignment } from "../../styles/Theme/styled-options";
import { IBSStyles } from "./box-structure.interfaces";

export const BSContent = styled.div<ISpace & IAlign & IDownResponsive & IBSStyles>`
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  ${spacing};
  ${textAlignment};
  ${down};
`;

export const BSNavigation = styled.div<ISpace & IBSStyles>`
  display: flex;
  align-items: center;
  border-top: 1px solid
    ${({ hasTopBorder, theme }) =>
      hasTopBorder ? theme.colors.grey[100] : "transparent"};
  border-bottom: 1px solid
    ${({ hasBottomBorder, theme }) =>
      hasBottomBorder ? theme.colors.grey[100] : "transparent"};
  background-color: ${({ $bgColor, theme }) =>
    $bgColor === "darkBlue" ? theme.colors.darkBlue[500] : $bgColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  justify-content: ${({ $horizontalAlign }) =>
    $horizontalAlign === "between"
      ? "space-between"
      : $horizontalAlign === "center"
      ? "center"
      : $horizontalAlign === "left"
      ? "flex-start"
      : "flex-end"};

  ${({ isSticky, theme, isFooter }) =>
    isSticky
      ? css`
          position: sticky;
          top: ${isFooter ? "auto" : "0"};
          bottom: ${isFooter ? "0" : "auto"};
          left: 0;
          right: 0;
          z-index: ${isFooter ? theme.zIndex.stickyFooter : theme.zIndex.stickyTop};
        `
      : undefined}

  ${({ $spacing }) =>
    spacing({
      $spacing: $spacing || {
        p: "16px",
      },
    })}

  &:first-child {
    border-top-left-radius: ${({ theme, $borderRadius }) =>
      $borderRadius || theme.spaceRadius.xxms};
    border-top-right-radius: ${({ theme, $borderRadius }) =>
      $borderRadius || theme.spaceRadius.xxms};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme, $borderRadius }) =>
      $borderRadius || theme.spaceRadius.xxms};
    border-bottom-right-radius: ${({ theme, $borderRadius }) =>
      $borderRadius || theme.spaceRadius.xxms};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ${TitleWrapper} {
      > svg {
        display: none;
      }
    }
  }
`;

const BSWrapper = styled.div<ISpace>`
  border-radius: ${({ theme }) => theme.spaceRadius.xxms};
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow.popup};
  ${({ $spacing }) =>
    spacing({
      $spacing: $spacing || {
        mb: "24px",
      },
    })}

  &:last-child {
    margin-bottom: 0;
  }
`;

export default BSWrapper;
