import React from "react";

const Form: React.FC<React.HTMLAttributes<HTMLFormElement>> = ({
  children,
  ...props
}) => {
  return (
    <form {...props} noValidate>
      {children}
    </form>
  );
};

export default Form;
