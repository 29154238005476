import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import { titleVar } from "../../common/apollo/vars/title.var";

import { useAuth } from "../../hooks/useAuth.hook";
import { useLogout } from "../../hooks/useLogout.hook";

import SharedTopbar from "shared/components/layout/topbar/topbar.component";

const Topbar = () => {
  const title = useReactiveVar(titleVar);

  const { user } = useAuth();
  const logout = useLogout();

  return (
    <SharedTopbar
      title={title}
      userFirstName={user?.firstName || ""}
      userLastName={user?.lastName || ""}
      userPhoto={user?.image?.url}
      userTitle="District Admin"
      setExpanded={sidebarExpandedVar}
      logout={logout}
    />
  );
};

export default Topbar;
