import { useApolloClient } from "shared/providers/apollo/apollo.package";
import { useNavigate } from "shared/providers/router/router.package";
import { ADMIN_AUTH_TOKEN } from "shared/common/data/constants";
import { removeLocalStorageItem } from "shared/utils/local-storage";

/**
 * Logs out the user by resetting the cached store and removes auth token from localStorage
 * @returns async logout function
 */

export const useLogout = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const logout = async () => {
    removeLocalStorageItem(ADMIN_AUTH_TOKEN);
    await client.clearStore();
    navigate("/login");
  };

  return logout;
};
