import { useCallback, useMemo } from "react";
import { toast } from "shared/providers/toast/toast.package";
import {
  generatePath,
  useNavigate,
  useParams,
} from "shared/providers/router/router.package";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import { districtVar } from "../../common/apollo/vars/district.var";

import { useLogout } from "../../hooks/useLogout.hook";

import SharedSidebar from "shared/components/layout/sidebar/sidebar.component";

import {
  TbHome,
  TbUsers,
  TbBuildingCommunity,
  TbSettings,
  TbPlug,
  TbTransferIn,
} from "shared/providers/react-icons/tb.package";

import { useGetDistrictsQuery } from "../../generated/graphql";

import logo from "shared/assets/logo-only.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const params = useParams<{
    id?: string;
    "*"?: string;
  }>();

  const expanded = useReactiveVar(sidebarExpandedVar);
  const district = useReactiveVar(districtVar);

  const logout = useLogout();

  const { data, fetchMore } = useGetDistrictsQuery({
    onError: (err) => {
      console.log(err);
      toast.error(
        `Some districts might not be available in switcher. Error: ${err.message}`
      );
    },
  });

  const getMoreData = useCallback(() => {
    if (
      data?.getDistricts.pagination.hasNextPage &&
      data.getDistricts.pagination.endCursor
    ) {
      fetchMore({
        variables: {
          after: data.getDistricts.pagination.endCursor,
        },
      });
    }
  }, [data, fetchMore]);

  const selectDistrict = useCallback(
    (id: string) =>
      navigate(
        generatePath("/districts/:id/*", {
          id,
          "*": params["*"] || "",
        })
      ),
    [params, navigate]
  );

  const backToDistricts = useCallback(() => {
    navigate("/districts");

    districtVar(null);
  }, [navigate]);

  const pages = useMemo(() => {
    if (district) {
      return [
        {
          icon: TbBuildingCommunity,
          name: "Schools",
          url: `/districts/${district.id}/schools`,
        },
        {
          icon: TbUsers,
          name: "Users",
          url: `/districts/${district.id}/users`,
        },
        {
          icon: TbPlug,
          name: "Integrations",
          url: `/districts/${district.id}/integrations`,
        },
        {
          icon: TbTransferIn,
          name: "Clever",
          url: `/districts/${district.id}/clever`,
        },
        {
          icon: TbSettings,
          name: "Settings",
          url: `/districts/${district.id}/settings`,
        },
      ];
    } else {
      return [
        {
          icon: TbHome,
          name: "Districts",
          url: "/districts",
        },
        {
          icon: TbUsers,
          name: "Users",
          url: "/users",
        },
      ];
    }
  }, [district]);

  const switcherData = useMemo(
    () => ({
      id: district?.id || "default-practice-switcher",
      name: district?.name || "Practice Software",
      logo: district ? district.logo?.url : logo,
    }),
    [district]
  );

  const switcherItems = useMemo(
    () =>
      district && data?.getDistricts.data?.length
        ? data.getDistricts.data
            .map((district) => ({
              id: district.id,
              name: district.name,
              logo: district.logo?.url,
              onClick: () => selectDistrict(district.id),
              closeOnClick: true,
            }))
            .sort((a, b) => {
              if (a.id === district.id) {
                return -1;
              }

              if (b.id === district.id) {
                return 1;
              }

              return 0;
            })
        : null,
    [district, data?.getDistricts.data, selectDistrict]
  );

  const switcherSecondaryItems = useMemo(
    () =>
      district
        ? [
            {
              id: "back-to-all-districts",
              name: "All Districts",
              logo: logo,
              onClick: backToDistricts,
              closeOnClick: true,
            },
          ]
        : null,
    [district, backToDistricts]
  );

  return (
    <SharedSidebar
      pages={pages}
      expanded={expanded}
      setExpanded={sidebarExpandedVar}
      onLogout={logout}
      switcher={switcherData}
      switcherItems={switcherItems}
      secondarySwitcherItems={switcherSecondaryItems}
      fetchMoreSwitcherItems={
        data?.getDistricts.pagination.hasNextPage &&
        data.getDistricts.pagination.endCursor
          ? getMoreData
          : null
      }
    />
  );
};

export default Sidebar;
